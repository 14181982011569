
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { ItemsPerPage } from '@common/types';
import { PromiseHolder } from '@common/utils/PromiseHolder';
import { fetchNews } from '@web/handlers/fetchNews';
import { fetchNewsCategory } from '@web/handlers/fetchNewsCategory';
import { fetchTagNewsListPromoSchedule } from '@web/handlers/fetchTagNewsListPromoSchedule';
import { getNextWebServerSidePropsWrapper, GetProps, GetPropsReturn, NextWebServerSideProps, Route, } from '@web/routing';
import { CategoryNews, Props, State } from '@web/templates/CategoryNews';
const prepareProps: GetProps<Props> = async (contextData, serverContext): GetPropsReturn<Props> => {
    const state: PromiseHolder<State> = {
        currentPage: 1,
        newsItems: [],
        promoItems: []
    };
    const props: PromiseHolder<Props> = {
        contextData,
        state
    };
    const slug = String(serverContext.query.slug) || '';
    const newsCategory = await fetchNewsCategory(contextData, slug, false);
    // redirect to home page if news category !== exists
    if (!newsCategory) {
        return {
            notFound: true
        };
    }
    state.newsCategory = newsCategory;
    const [news, promoItems] = await Promise.all([
        fetchNews({
            contextData,
            isClientSide: false,
            newsCatID: newsCategory.id,
            perPage: ItemsPerPage.HUGE
        }),
        fetchTagNewsListPromoSchedule(contextData),
    ]);
    if (news.data) {
        state.newsItems = news.data;
        state.pagination = news.pagination;
    }
    if (promoItems) {
        state.promoItems = promoItems;
    }
    contextData.alternateLinks = newsCategory.alternateLinks;
    return props;
};
const getServerSideProps: NextWebServerSideProps<Props> = getNextWebServerSidePropsWrapper(prepareProps, Route.CategoryNews);
export const Home = CategoryNews;
export default Home;

    async function __Next_Translate__getServerSideProps__1916afde5b2__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/category-news/[[...slug]]',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1916afde5b2__ as getServerSideProps }
  